import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class portfolioSearchService {

  rutApiPro = "https://api.fundsfairway.com";
  rutApiTest = "http://13.39.148.219";
  rutUse = this.rutApiPro;

  constructor( private http : HttpClient) {

  }


  getPortfolioSpecifications(): Observable<any>{
    return this.http.get(`${this.rutUse}/api/black_litterman_model/portfolio_specifications/ `);
  }

  getTest(): Observable<any>{
    return this.http.get(`${this.rutUse}/api/black_litterman_model/investment_test_inputs/ `);
  }

  postTest(req:any): Observable<any>{
    return this.http.post(`${this.rutUse}/api/black_litterman_model/create_investment_test/`,req);
  }

  getEsg():Observable<any>{
    return this.http.get(`${this.rutUse}/api/value_for_money/get_filters/ `);
  }


  getPortfolio():Observable<any>{
    return this.http.get(`${this.rutUse}/api/black_litterman_model/portfolio_form_values/`);
  }

  getOnePortfolioData(req:any):Observable<any>{
    return this.http.get(`${this.rutUse}/api/black_litterman_model/portfolio/?name=${req}`);
  }

  createPortfolio(req:any): Observable<any>{
    return this.http.post(`${this.rutUse}/api/black_litterman_model/portfolio_create/`,req);
  }

  getChart(req:any): Observable<any>{
    return this.http.post(`${this.rutUse}/api/black_litterman_model/portfolio/values/`,req);
  }

  getBenchmark(req:any){
    return this.http.post(`${this.rutUse}/api/value_for_money/get_benchmarks/`, req);
  }

  
  saveFilterEsg(req:any){
  
    return this.http.post(`${this.rutUse}/api/common/esg/save/ `, req);

  }

  getFiltersFisu():Observable<any>{
    return this.http.get(`${this.rutUse}/api/value_for_money/get_filters/`);
  }

  postBuildingBlock(req:any):Observable<any>{
    return this.http.post(`${this.rutUse}/api/black_litterman_model/building_block_creator_options/`,req);
  }


  postViewsData(req:any):Observable<any>{
    return this.http.post(`${this.rutUse}/api/black_litterman_model/views/`,req);

  }


  LaunchBlackLittermanModel(req:any):Observable<any>{
    return this.http.post(`${this.rutUse}/api/black_litterman_model/launch_black_litterman_model/`,req);

  }

  reviewConstrains(req:any):Observable<any>{
    return this.http.post(`${this.rutUse}/api/black_litterman_model/validate_building_blocks_constrains/`,req);

  }


  getWeights(req:any):Observable<any>{


    return this.http.post(`${this.rutUse}/api/black_litterman_model/weights/`,req);
  
    
  }
  


  getManagers():Observable<any>{
    return this.http.get(`${this.rutUse}/api/black_litterman_model/managers/`);
  }







  
}